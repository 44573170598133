import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import useEvent from "../hooks/useEvent.hook";
import { Colors } from "@sellout/ui/build/Colors";
import PromotionCodeInput from "./PromotionCodeInput";
import { EPurchasePortalModes } from "@sellout/models/.dist/enums/EPurchasePortalModes";
import { EventPromotionTypeEnum } from "@sellout/models/.dist/interfaces/IEventPromotion";

type LinePadding = {
  padding?: string;
};
const Container = styled.div`
  position: relative;
`;

const HeaderBar = styled.div<LinePadding>`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.SurfaceTertiary};
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.padding? props.padding :"24px 24px 8px"};

`;

const Blank = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  background-color: ${Colors.SurfaceTertiary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type ScreenHeaderProps = {
  title?: string;
  showPromotionButton?: boolean;
  showDiscountButton?: boolean;
  setShowDiscountCodeInput?: Function;
  showDiscountCodeInput?: boolean;
  blank?: boolean;
  padding?:string
};

const ScreenHeader: React.FC<ScreenHeaderProps> = ({
  blank = false,
  padding
}) => {
  /** State **/
  const { event } = useEvent();
  const {
    app: { mode },
  } = useSelector((state: PurchasePortalState) => state);

  const [, setShowPromotionCodeInput] = useState(false);
  const [, setShowPromotionCodeButton] = useState(false);
  const hasVisiblePromotions = !!(
    mode === EPurchasePortalModes.Checkout &&
    event?.promotions?.some((promotion) => promotion?.active)
  );
  if (blank) {
    return (
      <Container>
        <Blank />
      </Container>
    );
  }

  
  const isPresaleCodeIsActive = event?.promotions?.some(
    (promotion) => (promotion?.type === EventPromotionTypeEnum.PreSale || promotion?.type === EventPromotionTypeEnum.Unlock)  && promotion?.active
  )


  /** Render **/
  return (
    <Container>
      <HeaderBar padding={ isPresaleCodeIsActive? padding :"0px"}>
        {/* <HeaderBar padding="0px"} */}
      </HeaderBar>
      {isPresaleCodeIsActive && (
      <PromotionCodeInput
        open={hasVisiblePromotions}
        close={() => setShowPromotionCodeInput(false)}
        setShowPromotionCodeButton={setShowPromotionCodeButton}
      />
      )}
    </Container>
  );
};

export default ScreenHeader;
